<style lang="postcss">
.g-top-table {
  border-spacing: 7px 5px;
  & td {
  }
  & td:first-child {
    padding-right: 0;
    width: 50px;
  }
  & td:nth-child(2) {
    padding-right: 21px;
    width: 60px;
  }
  & thead {
    @apply font-sb_sans_caps hidden md:table-row-group;
    font-size: 20px;
    line-height: 120%;
    color: #63df8d;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    & td {
      @apply text-left;
      padding-bottom: 17px;
    }
  }
  & tbody {
    @apply font-chava;
    font-size: 16px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.5);
    & tr {
      &.current {
        & td {
          color: #63df8d;
        }
      }
      &.top {
        & td {
          color: #ffc700;
        }
      }
    }
    & td {
      height: 57px;
      &:first-child {
        @apply text-center;
      }
      &:nth-child(2) {
        @apply relative font-sb_sans_text;
        font-weight: 600;
        color: #ffc700;
        font-size: 16px;
        line-height: 140%;
        padding-left: 12px;
        &:after {
          content: '';
          @apply absolute rounded;
          background: rgba(255, 255, 255, 0.1);
          width: 90px;
          height: 30px;
          left: 0;
          top: 14px;
        }
      }
      &:nth-child(3) {
        @apply text-left whitespace-nowrap overflow-hidden overflow-ellipsis;
        color: #ffffff;
        max-width: 200px;
      }
    }
  }
}
@screen md {
  .g-top-table {
    & td {
    }
    & td:first-child {
      padding-right: 27px;
      width: 61px;
    }
    & td:nth-child(2) {
      padding-right: 39px;
      width: 61px;
    }
    & thead {
      & td {
      }
    }
    & tbody {
      font-size: 22px;
      & tr {
        &.current {
          & td {
          }
        }
      }
      & td {
        &:first-child {
        }
        &:nth-child(2) {
          font-size: 18px;
          &:after {
            width: 100px;
            height: 33px;
            top: 12px;
          }
        }
        &:nth-child(3) {
        }
      }
    }
  }
}
</style>

<template>
  <table class="g-top-table">
    <thead v-if="!hideHeader">
      <tr>
        <td v-for="field in fields" :key="`head-${field.id}`">
          {{ field.name }}
        </td>
      </tr>
    </thead>
    <tbody>
      <template v-if="players && players.length">
        <tr
          v-for="(player, idx) in players"
          :key="idx"
          :class="{
            current: user && user.username === player.username,
            top: idx < 5,
          }"
        >
          <td
            v-for="field in fields"
            :key="`player-${player.name}-${field.id}`"
          >
            {{ player[field.id] }}
          </td>
        </tr>
        <tr v-if="showUserOutsideTable" class="current">
          <td v-for="field in fields" :key="`user-${field.id}`">
            {{ user[field.id] }}
          </td>
        </tr>
      </template>
      <tr v-else>
        <td :colspan="fields.length">Нет результатов</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'TopTable',
  data() {
    return {};
  },
  props: {
    fields: {
      type: Array,
      default: () => [
        { name: 'Место', id: 'position' },
        { name: 'Время', id: 'duration' },
        { name: 'Имя', id: 'username' },
      ],
    },
    players: { type: Array, default: () => [] },
    user: {
      type: Object,
      default: () => {
        return {};
      },
    },
    hideHeader: {
      type: Boolean,
    },
  },
  computed: {
    showUserOutsideTable() {
      return (
        this.user?.user_id &&
        this.user.position &&
        !this.players.find((player) => player.username === this.user.username)
      );
    },
  },
};
</script>
