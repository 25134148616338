<style lang="postcss">
.game-page-content {
  @apply font-sb_sans_caps text-left mx-auto w-full lg:w-775px pb-16;
  color: #ffffff;
  margin-top: 20px;
}
@screen md {
  .game-page-content {
    margin-top: 10%;
  }
}

.game-input {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #ffffff;
  height: 64px;
  border-radius: 4px;
  font-size: 20px;
  line-height: 28px;
}

.show-top-link {
  @apply font-chava uppercase;
  font-size: 16px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.7);
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
}
</style>

<template>
  <div class="game-page-content">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <level-complete
        v-if="secondLevelComplete"
        title="Круто, у тебя получилось!"
        class="mb-16"
      >
        <top-table
          :players="
            leaderboard && leaderboard.top && leaderboard.top.slice(0, 5)
          "
          :user="leaderboard.current"
          class="mx-auto mt-11 mb-4"
          hideHeader
        />
        <router-link :to="{ name: 'Top' }" class="show-top-link mb-9"
        >смотреть топ 15</router-link
        >
        <div v-if="gameResult" class="mt-6">
          <p class="text-accent mb-6">Поделись результатом и игрой с другом</p>
          <copy-link :link="shareLink" class="justify-center" />
        </div>
      </level-complete>
      <template v-else>
        <div>
          <p class="game-level animate fadeInDown one">2 уровень</p>
          <h2 class="game-supertitle mt-3 hidden md:block animate fadeInUp two">
            Предыдущий пароль слишком прост, если ты крипто-инвестор
          </h2>
          <h1 class="game-title-2 mb-16 mt-1 md:mt-10 animate fadeInUp three">
            Пароль для крипто-инвестора
          </h1>
          <game-password class="animate fadeInUp four" />
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import GamePassword from "@/components/UI/GamePassword.vue";
import LevelComplete from "@/components/LevelComplete.vue";
import TopTable from "@/components/TopTable.vue";
import CopyLink from "@/components/CopyLink.vue";

export default {
  components: { GamePassword, LevelComplete, TopTable, CopyLink },
  name: "GamePage",
  data() {
    return {};
  },
  created() {
    if (this.secondLevelComplete) {
      this.$store.dispatch("game/fetchLeaderboard");
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {},
  computed: {
    secondLevelComplete() {
      return this.$store.state.game.secondLevelComplete;
    },
    leaderboard() {
      return this.$store.state.game.leaderboard;
    },
    shareLink() {
      return `${window.location.host}/results/${this.gameResult.hash}`;
    },
    gameResult() {
      return this.$store.state.user.currentUserGame;
    },
  },
  watch: {
    secondLevelComplete(val) {
      if (val) {
        this.$store.dispatch("game/fetchLeaderboard");
        this.$store.dispatch('user/fetchUser');
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>
